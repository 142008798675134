import Honeybadger from '@honeybadger-io/js';

export const logErrorAndReportToHoneybadger = ({
  error,
  isReportable = true,
}: {
  error: unknown;
  isReportable?: boolean;
}): void => {
  console.error(error);

  if (!isReportable) {
    return;
  }

  let honeybadgerError: Error | null = null;

  if (typeof error === 'string') {
    honeybadgerError = new Error(error);
  } else if (error instanceof Error) {
    honeybadgerError = error;
  }

  if (honeybadgerError) {
    Honeybadger.notify(honeybadgerError);
  }
};

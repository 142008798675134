/**
 * Number of milliseconds to wait before prompting providers with the option
 * to mark a call as "no show"
 */
export const ELAPSED_MS_BEFORE_NO_SHOW = 60000 * 5;

/**
 * Number of milliseconds to wait before auto-marking the call as attended. It requires
 * provider and member to be in the call
 */
export const ELAPSED_MS_BEFORE_AUTO_MARKING_AS_ATTENDED = 60000 * 5;

/**
 * The time to wait before auto-closing the pop-up
 */
export const TOAST_AUTO_CLOSE_DELAY_MS = 10000;

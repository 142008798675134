import mixpanel, { Dict } from 'mixpanel-browser';
import { ENVIRONMENT, MIXPANEL_TOKEN } from '../environment';
import { UserType } from '../video/types';

export const LEGACY_TRACKING_TAG_VIDEO_CALL_APP = 'twilio-video-app:';
export const LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL = 'twilio-video-app/telemedicine-modal:';

export enum LegacyTrackingEvent {
  TelemedicineClickContinue = 'click continue button',
  TelemedicineClickClose = 'click close button',
  TelemedicineOpen = 'open',
  VideoCallAppOpen = 'open app',
  VideoCallAppClickJoinCall = 'click join call button',
  VideoCallAppProviderSwitchToCamera = 'provider change to camera',
  VideoCallAppProviderSwitchToShareScreen = 'provider change to share screen',
  VideoCallAppLeaveCall = 'leave call',
}

export enum TrackingEvent {
  Click = 'Click',
  Task = 'Task',
  Visit = 'Visit',
}

export enum TrackingEventV2 {
  GroupClassRegister = 'schedule/group-classes: register for a class',
  GroupClassRegisterConfirmed = 'schedule/group-classes: class registration confirmed',
  GroupClassListClasses = 'schedule/group-classes: list classes',
  GroupClassSelectClass = 'schedule/group-classes: select class',
  GroupClassSelectClassSlot = 'schedule/group-classes: select class-slot',
  GroupClassListClassSlot = 'schedule/group-classes: list class-slot',
  GroupClassDetail = 'schedule/group-classes: class detail',
  ScheduleListOwnAppointments = 'schedule/appointments-list',
  ScheduleListMemberAppointments = 'schedule/appointments-list-for-member',
  ScheduleDetailOwnAppointment = 'schedule/appointment-detail',
  ScheduleDetailMemberAppointment = 'schedule/appointments-detail-for-member',
  ScheduleCancelOwnAppointment = 'schedule/appointment-cancel-confirmed',
  ScheduleCancelMemberAppointment = 'schedule/appointments-cancel-confirmed-for-member',
  ScheduleCancelDialogOwnAppointment = 'schedule/appointment-cancel-dialog',
  ScheduleCancelDialogMemberAppointment = 'schedule/appointments-cancel-dialog-for-member',
  ScheduleCancelDialogOwnAppointmentClickKeep = 'schedule/appointment-cancel-dialog:click-keep',
  ScheduleCancelDialogMemberAppointmentClickKeep = 'schedule/appointments-cancel-dialog-for-member:click-keep',
  ScheduleCancelDialogOwnAppointmentClickConfirm = 'schedule/appointment-cancel-dialog:click-confirm',
  ScheduleCancelDialogMemberAppointmentClickConfirm = 'schedule/appointments-cancel-dialog-for-member:click-confirm',
  VideoCallIsReadyToJoin = 'video-call: is ready to join',
  VideoCallJoin = 'video-call: join call',
  VideoCallLocalParticipantHasConnected = 'video-call/local-participant: has joined the room',
  VideoCallOpen = 'video-call: open page',
  VideoCallOutlineClickTopic = 'video-call/outline: click topic',
  VideoCallOutlineClose = 'video-call/outline: close',
  VideoCallOutlineCompleteTopic = 'video-call/outline: complete topic',
  VideoCallOutlineOpen = 'video-call/outline: open',
  VideoCallOutlineSkipTopic = 'video-call/outline: skip topic',
  VideoCallRemoteParticipantHasConnected = 'video-call/remote-participant: has joined the room',
  VideoCallStartRecording = 'video-call: start recording',
}

type TrackingProperties = Dict &
  (
    | {
        field: string;
        source?: string;
        value?: string | boolean;
      }
    | {
        field?: string;
        source: string;
        value?: string | boolean;
      }
  );

type IdentityDetail = {
  id?: number;
  name?: string;
  clinic_id?: number;
  schedule_id?: number;
  member_id?: number;
  provider_id?: number;
};

export default class MixpanelClient {
  public static init(): void {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: ENVIRONMENT === 'development',
    });
  }

  public static identifyUser(userUuid: string) {
    mixpanel.identify(userUuid);
  }

  public static registerUserMetaData({
    identityDetail,
    room,
    userType,
  }: {
    identityDetail?: IdentityDetail;
    room?: string;
    userType?: UserType;
  }): void {
    mixpanel.register({
      id: identityDetail?.id,
      user_type: userType,
      room: room,
      clinic_id: identityDetail?.clinic_id || undefined,
      schedule_id: identityDetail?.schedule_id || undefined,
      member_id: identityDetail?.member_id,
      provider_id: identityDetail?.provider_id,
    });
  }

  public static trackEvent({
    eventName,
    properties,
  }: {
    eventName: TrackingEvent | TrackingEventV2;
    properties: TrackingProperties;
  }) {
    mixpanel.track(eventName, properties);
  }

  /**
   * @deprecated This is how events in mixpanel were initially tracked
   * (Not respecting the standard as we do in all the other projects that provide tracking)
   */
  public static trackLegacyEventWithTag({
    tag,
    eventName,
    properties,
  }: {
    tag: typeof LEGACY_TRACKING_TAG_VIDEO_CALL_APP | typeof LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL;
    eventName: LegacyTrackingEvent;
    properties?: Dict;
  }): void {
    mixpanel.track(`${tag} ${eventName}`, properties);
  }
}

export enum ErrorTypes {
  /**
   * Mostly triggered when trying to create audio or video tracks OR
   * when attempting to share the screen
   */
  NotAllowedError = 'NotAllowedError',
  /**
   * Triggered when camera or microphone is being used by other app/tab
   */
  NotReadableError = 'NotReadableError',
  /**
   * Triggered when screen-sharing is not supported by the browser
   */
  TypeError = 'TypeError',
}

import { FC } from 'react';

const SelectIcon: FC = () => {
  return (
    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.421 2.5c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10Zm5.85 6.85-8.19 8.15-3.5-3.5a.502.502 0 0 1 .71-.71l2.82 2.82 7.47-7.47a.502.502 0 0 1 .71.71h-.02Z'
        fill='#1a667a'
      />
    </svg>
  );
};

export default SelectIcon;

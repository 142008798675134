export enum VideoCallStatus {
  Attended = 'attended',
  NoShow = 'no-show',
  Rescheduled = 'rescheduled',
}

export enum AllowedPlace {
  Home = 'home',
  Other = 'other',
}

export interface PlaceOfService {
  place_of_service: string;
  id: number;
}

export interface PlaceOfServiceRecord {
  memberId: number;
  scheduleId: number;
  authToken: string;
  place: AllowedPlace;
}

export type VideoCallStatusParams = {
  scheduleId: number;
  providerId: number;
  providerAuthToken: string;
  videoCallStatus: VideoCallStatus;
};

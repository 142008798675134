import { FC } from 'react';

const LocationIcon: FC = () => {
  return (
    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.255 18.55a1.467 1.467 0 1 0 0-2.933 1.467 1.467 0 0 0 0 2.933Zm-16.667-.633a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm24.717 16.65-3.7-10a1.283 1.283 0 0 0-1.117-.784h-1.083c1.35-2.283 2.85-5.033 2.85-6.7a5 5 0 0 0-10 0c0 1.534 1.5 4.367 2.85 6.667H17.77c1.667-3.083 3.15-6.3 3.15-8.25a8.333 8.333 0 1 0-16.666 0c0 2.033 1.666 5.433 3.333 8.633-.119.117-.21.26-.267.417l-2.866 10a.6.6 0 0 0 .633.867h31.667a.55.55 0 0 0 .55-.85Zm-8.05-20.817a3.333 3.333 0 0 1 3.333 3.333c0 1.117-1.55 4.117-3.333 6.934-1.784-2.767-3.334-5.767-3.334-6.934a3.333 3.333 0 0 1 3.334-3.333Zm-16.667-5a6.666 6.666 0 0 1 6.667 6.75c0 2.583-3.667 9.033-6.667 13.6-3-4.567-6.667-11.017-6.667-13.6a6.667 6.667 0 0 1 6.667-6.75Zm-6.117 25 2.217-7.717c1.983 3.3 3.9 6.05 3.9 6.05s2.133-3.05 4.233-6.666h11.317c.65 1 1.117 1.666 1.117 1.666s.466-.666 1.116-1.666h1.784l3.05 8.333H6.47Z'
        fill='#1a667a'
      />
    </svg>
  );
};

export default LocationIcon;

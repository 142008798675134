import { FC } from 'react';
import {
  ToastContainer as RTToastContainer,
  ToastOptions,
  TypeOptions,
  toast,
} from 'react-toastify';
import './styles.css';

function toastWithCustomOptions(type: TypeOptions) {
  return (msg: string, opt?: ToastOptions<{}>) =>
    toast(msg, {
      position: 'top-center',
      autoClose: false,
      closeButton: true,
      closeOnClick: true,
      draggable: true,
      theme: 'light',
      type,
      ...opt,
    });
}

export const ToastContainer: FC = () => (
  <RTToastContainer
    position='top-center'
    autoClose={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable
    theme='light'
  />
);

const Toast = {
  error: toastWithCustomOptions(toast.TYPE.ERROR),
  info: toastWithCustomOptions(toast.TYPE.INFO),
  success: toastWithCustomOptions(toast.TYPE.SUCCESS),
  warning: toastWithCustomOptions(toast.TYPE.WARNING),
};

export default Toast;

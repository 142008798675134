import {
  AudioVideoObserver,
  ConsoleLogger,
  ContentShareObserver,
  DefaultBrowserBehavior,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  VideoSource,
  VideoTileState,
} from 'amazon-chime-sdk-js';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import MixpanelClient, { TrackingEventV2 } from '../../lib/tracking/mixpanel';
import { IOVideoSetupResult, Participant } from './meeting-call.types';

export type ObserverHandler = {
  localParticipantUpdated(participant: Participant): void;
  remoteParticipantsUpdated(participants: Participant[]): void;
};

export class VideoController implements AudioVideoObserver, ContentShareObserver {
  readonly logger: ConsoleLogger;
  readonly deviceController: DefaultDeviceController;
  private session: DefaultMeetingSession | null = null;
  private meeting: unknown | null = null;
  private attendee: { AttendeeId: string } | null = null;
  private remoteParticipants: Participant[] = [];
  private customObserver: ObserverHandler;

  constructor(meeting: unknown, attendee: { AttendeeId: string }, observer: ObserverHandler) {
    this.logger = new ConsoleLogger('EnaraLogger', LogLevel.ERROR);
    this.deviceController = new DefaultDeviceController(this.logger);
    this.session = null;
    this.meeting = meeting;
    this.attendee = attendee;
    this.customObserver = observer;
  }

  public async startSetup(): Promise<IOVideoSetupResult> {
    try {
      this.createConfiguration();
      const setupResult = await this.setupIO('default', 'default');
      if (!setupResult.success) {
        return setupResult;
      }
    } catch (error) {
      logErrorAndReportToHoneybadger({ error: `[VideoController::startSetup] error: ${error}` });
      return {
        success: false,
        message: `Problems configuring the audio and video`,
      };
    }

    if (this.session) {
      this.session.audioVideo.addObserver(this);
      this.session.audioVideo.addContentShareObserver(this);
      this.session.audioVideo.realtimeSubscribeToAttendeeIdPresence(
        (attendeeId, present, externalUserId, dropped, posInFrame) => {
          // This function is called whenever a remote participant join or quit the call
          const remoteParticipant = this.remoteParticipants.find(
            (participant) => participant.tileState.boundAttendeeId === attendeeId
          );
          if (remoteParticipant && (!present || dropped)) {
            this.removeRemoteParticipant(attendeeId);
          }

          // Then track event for the local participant
          if (this.attendee?.AttendeeId === attendeeId) {
            MixpanelClient.trackEvent({
              eventName: TrackingEventV2.VideoCallLocalParticipantHasConnected,
              properties: { source: 'unknown', attendeeId },
            });
          }
        }
      );
    }

    return {
      success: true,
      session: this.session,
    };
  }

  private removeRemoteParticipant(attendeeId: string) {
    this.remoteParticipants = this.remoteParticipants.filter(
      (participant) => participant.tileState.boundAttendeeId !== attendeeId
    );

    this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
  }

  private createConfiguration() {
    if (!this.meeting || !this.attendee) {
      return;
    }

    const configuration = new MeetingSessionConfiguration(this.meeting, this.attendee);
    this.session = new DefaultMeetingSession(configuration, this.logger, this.deviceController);
  }

  private async setupIO(audioId: string, videoId: string): Promise<IOVideoSetupResult> {
    if (!audioId || !videoId || !this.session) {
      return { success: false, message: 'Problems to configure the audio and video' };
    }

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (err) {
      logErrorAndReportToHoneybadger({
        error: `browser doesn't have permissions enabled for microphone and camera ${
          (this.meeting as any).MeetingId
        }`,
      });

      return {
        success: false,
        message:
          'Please enable access to your microphone and camera in your browser settings to continue.',
      };
    }

    if (audioId) {
      const audioInputDevices = await this.session.audioVideo.listAudioInputDevices();

      if (audioInputDevices.length > 0) {
        const found = audioInputDevices.find((audio) => {
          return audio.deviceId === audioId;
        });

        const audioDeviceId = found ? audioId : audioInputDevices[0].deviceId;
        await this.session.audioVideo.startAudioInput(audioDeviceId);

        this.session.audioVideo.realtimeUnmuteLocalAudio();
      }
    }

    const audioOutputList = await this.session.audioVideo.listAudioOutputDevices();
    try {
      const defaultBrowserBehavior = new DefaultBrowserBehavior();
      if (defaultBrowserBehavior.supportsSetSinkId()) {
        if (audioOutputList.length > 0) {
          await this.session.audioVideo.chooseAudioOutput(audioOutputList[0].deviceId);
        } else {
          await this.session.audioVideo.chooseAudioOutput(null);
        }
      } else {
        logErrorAndReportToHoneybadger({
          error: `[Debug-no error] The browser doesn't support SinkId meeting: ${
            (this.meeting as any).MeetingId
          }`,
        });
      }
    } catch (err) {
      logErrorAndReportToHoneybadger({ error: `Can't select output audio ${err}` });

      return {
        success: false,
        message: `Can't select output audio. Please talk to development about this problem.`,
      };
    }

    if (videoId) {
      const videoInputDevices = await this.session.audioVideo.listVideoInputDevices();

      await this.session.audioVideo.stopVideoInput();
      if (videoInputDevices.length > 0) {
        const defaultVideoId = videoInputDevices[0].deviceId;
        await this.session.audioVideo.startVideoInput(
          videoId === 'default' ? defaultVideoId : videoId
        );
      }
    }

    return {
      success: true,
    };
  }

  public async unbindVideoIO() {
    await this.session!.audioVideo.stopVideoInput();
    await this.session!.audioVideo.stopAudioInput();
    this.session!.audioVideo.stop();
  }

  public async stopVideo() {
    this.session!.audioVideo.stopLocalVideoTile();
    await this.session!.audioVideo.stopVideoInput();
  }

  public async startVideo() {
    const deviceId = (await this.session!.audioVideo.listVideoInputDevices())[0].deviceId;
    await this.session!.audioVideo.startVideoInput(deviceId);
    this.session!.audioVideo.startLocalVideoTile();
  }

  private localTileUpdateObserver(tileState: VideoTileState) {
    if (!tileState.boundAttendeeId || !tileState.localTile) {
      return;
    }

    this.customObserver.localParticipantUpdated({
      isCameraEnabled: !!tileState?.boundVideoStream?.active,
      isRemote: false,
      tileState,
    });
  }

  private remoteTileUpdateObserver(tileState: VideoTileState) {
    if (!tileState.boundAttendeeId || tileState.localTile || tileState.isContent) {
      return;
    }

    const indexFound = this.remoteParticipants.findIndex(
      (p) => p.tileState.boundAttendeeId === tileState.boundAttendeeId
    );

    if (indexFound !== -1 && !tileState.boundVideoElement) {
      const found = this.remoteParticipants[indexFound];

      this.session!.audioVideo.unbindVideoElement(found.tileState.tileId!, true);
      if (found.boundElement && found.boundElement.current) {
        this.session!.audioVideo.bindVideoElement(tileState.tileId!, found.boundElement.current);
      }

      this.remoteParticipants.splice(indexFound, 1);

      // Adding the new participant who is bound
      this.remoteParticipants.push({
        isCameraEnabled: !!tileState?.boundVideoStream?.active,
        isRemote: true,
        tileState,
        isSharing: tileState.isContent,
        boundElement: found.boundElement,
      });

      this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);

      return;
    }

    if (indexFound !== -1 && tileState.active) {
      return;
    }

    this.remoteParticipants.push({
      isCameraEnabled: !!tileState?.boundVideoStream?.active,
      isRemote: true,
      tileState: tileState,
      isSharing: tileState.isContent,
    });

    this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
  }

  private sharingTileUpdateObserver(tileState: VideoTileState) {
    if (!tileState.boundAttendeeId || tileState.localTile || !tileState.isContent) {
      return;
    }

    const indexFound = this.remoteParticipants.findIndex(
      (p) => p.tileState.boundAttendeeId === tileState.boundAttendeeId
    );

    if (indexFound !== -1 || tileState.active) {
      return;
    }

    this.remoteParticipants.push({
      isCameraEnabled: !!tileState?.boundVideoStream?.active,
      isRemote: true,
      tileState: tileState,
      isSharing: tileState.isContent,
    });

    this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
  }

  audioVideoDidStart(): void {
    this.session!.audioVideo.startLocalVideoTile();
  }

  videoTileDidUpdate(tileState: VideoTileState) {
    this.localTileUpdateObserver(tileState);
    this.remoteTileUpdateObserver(tileState);
    this.sharingTileUpdateObserver(tileState);
  }

  remoteVideoSourcesDidChange(videoSources: VideoSource[]) {
    // We iterate over all remote participants in order to update isCameraEnabled state
    this.remoteParticipants = this.remoteParticipants.map((remoteParticipant) => {
      const videoSourceForRemoteParticipant = videoSources.findIndex(
        (videoSource) =>
          videoSource.attendee.attendeeId === remoteParticipant.tileState.boundAttendeeId
      );

      if (videoSourceForRemoteParticipant === -1) {
        // Video source not found means that the attendee has the camera disabled
        return { ...remoteParticipant, isCameraEnabled: false };
      }

      return { ...remoteParticipant, isCameraEnabled: true };
    });

    // Then update remote participants
    this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
  }

  videoTileWasRemoved(tileId: number) {
    const foundIndex = this.remoteParticipants.findIndex((p) => {
      return p.isSharing;
    });

    if (foundIndex !== -1) {
      const found = this.remoteParticipants[foundIndex];
      if (found.isSharing && !found.isCameraEnabled) {
        this.session!.audioVideo.unbindVideoElement(found.tileState.tileId!);

        this.remoteParticipants.splice(foundIndex, 1);
        this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
      }
    }
  }

  contentShareDidStop() {
    const sharingIndex = this.remoteParticipants.findIndex((p) => {
      return p.isSharing;
    });

    if (sharingIndex !== -1) {
      const found = this.remoteParticipants[sharingIndex];

      this.session!.audioVideo.unbindVideoElement(found.tileState.tileId!);

      this.remoteParticipants.splice(sharingIndex, 1);
      this.customObserver.remoteParticipantsUpdated(this.remoteParticipants);
    }
  }
}

import { FC } from 'react';

const CloseIcon: FC = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.08 11.9206L2 3.90476L3.92 2L12 10.0159L20.08 2L22 3.90476L13.92 11.9206L14 12L13.92 12.0794L22 20.0952L20.08 22L12 13.9841L3.92 22L2 20.0952L10.08 12.0794L10 12L10.08 11.9206Z'
        fill='#424242'
      />
    </svg>
  );
};

export default CloseIcon;

import { FC } from 'react';

const HomeIcon: FC = () => {
  return (
    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M39.861 18.035 23.4 4.883a4.777 4.777 0 0 0-2.978-1.05 4.777 4.777 0 0 0-2.978 1.05L.993 18.035a1.47 1.47 0 0 0-.372.433 1.536 1.536 0 0 0 .086 1.652c.228.316.566.525.941.581.375.056.757-.046 1.06-.283l3.738-2.978V31.21a4.388 4.388 0 0 0 1.207 3.031 4.039 4.039 0 0 0 2.908 1.258H30.27a4.039 4.039 0 0 0 2.908-1.258 4.388 4.388 0 0 0 1.207-3.03V17.403l3.75 2.978c.303.237.684.34 1.06.283a1.42 1.42 0 0 0 .94-.58c.228-.316.326-.714.272-1.105a1.502 1.502 0 0 0-.557-.98l.011.035ZM23.142 32.523h-5.43v-8.196c0-.75.286-1.47.795-2.001a2.66 2.66 0 0 1 1.92-.829c.72 0 1.41.298 1.92.829.51.53.795 1.25.795 2v8.197Zm8.385-1.31a1.35 1.35 0 0 1-.366.928 1.248 1.248 0 0 1-.409.284 1.207 1.207 0 0 1-.482.098h-4.276v-8.196a5.99 5.99 0 0 0-1.629-4.105 5.502 5.502 0 0 0-2.85-1.589 5.358 5.358 0 0 0-3.219.33 5.624 5.624 0 0 0-2.499 2.139 5.977 5.977 0 0 0-.937 3.225v8.196h-4.287c-.334 0-.654-.138-.89-.384a1.34 1.34 0 0 1-.368-.927V15.13l9.866-7.88a1.988 1.988 0 0 1 1.24-.439c.449 0 .885.154 1.24.438l9.866 7.875V31.21Z'
        fill='#1a667a'
      />
    </svg>
  );
};

export default HomeIcon;

export enum TopicStatus {
  Completed = 'completed',
  Skipped = 'skipped',
}

export enum KnownTopicSlug {
  EnaraInstruction = 'enara-introduction',
  BariatricHistory = 'bariatric-history',
  MedicalAndFamilyHistory = 'medical-and-family-history',
  MedicationAllergyLabReview = 'medication-allergy-lab-review',
  ReviewOfSystems = 'review-of-systems',
  LifestyleAndSocialHistory = 'lifestyle-and-social-history',
  SleepHistory = 'sleep-history',
  MoodScreen = 'mood-screen',
  ActivityHistory = 'activity-history',
  BmiAndComorbidities = 'bmi-and-comorbidities',
  ReviewObesityTreatment = 'review-obesity-treatment',
  WeightLossMedications = 'weight-loss-medications',
  NextSteps = 'next-steps',
  MedicalRecordsSummary = 'medical-records-summary',
}

export type OutlineProgressEntry = {
  topicSlug: KnownTopicSlug;
  topicStatus: TopicStatus;
};

export type HydratedTopic = {
  title: string;
  slug: KnownTopicSlug;
  prompt?: {
    title: string;
    suggestions?: string[];
  };
  items?: string[];
  llmResult: {
    pvq: string[] | null;
    hie: { title: string; summary: string[] }[] | null;
  };
};

export type HydratedOutline = {
  memberId: number;
  appointmentId: number;
  version: string;
  sections: {
    title: string;
    topics: HydratedTopic[];
  }[];
};
